@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'CustomBold';
    src: url(../src/assets/fonts/Poppins-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'CustomMedium';
    src: url(../src/assets/fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'CustomLight';
    src: url(../src/assets/fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'CustomItalic';
    src: url(../src/assets/fonts/Poppins-Italic.ttf) format('truetype');
}

@font-face {
    font-family: 'CustomItalicLight';
    src: url(../src/assets/fonts/Poppins-LightItalic.ttf) format('truetype');
}
